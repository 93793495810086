import React from "react"
import MainLayout from "../layouts/MainLayout"
import Seo from "../components/Seo"
import CardsAgenda from "../components/CardsAgenda"
import { graphql } from "gatsby"
import "../i18n"
import useChangeLanguage from "../hooks/useChangeLanguage"

export default function Agenda({ data, pageContext }) {
  const { locale } = pageContext
  useChangeLanguage({ locale })

  return (
    <MainLayout alternativeSlug={locale === 'tr-TR' ? '/agenda' : '/gundem'} locale={locale}>
      <Seo
        title={`${data.strapiCustomSeo.seo.title}`}
        description={`${data.strapiCustomSeo.seo.description}`}
      />
      {/* <div className="container breadcrumb">
        <span>Anasayfa</span> &gt; <span>Gündem</span>
      </div> */}
      {data.allStrapiBlogTag.edges.map(e => {
        const blogPosts = data.allStrapiBlogPost.nodes
          .filter(x => x.tag.id === e.node.id).sort((a, b) => {
            // Prioritize pinned posts
            if (a.pin && !b.pin) return -1;
            if (!a.pin && b.pin) return 1;

            // If both dates are null or both are pinned, sort based on createdAt
            if ((!a.date && !b.date) || (a.pin && b.pin)) return new Date(b.createdAt) - new Date(a.createdAt);

            // If only one date is null, place it at the end
            if (!a.date) return 1;
            if (!b.date) return -1;

            // If neither date is null and neither are pinned, sort based on date first, then createdAt
            const dateDiff = new Date(b.date) - new Date(a.date);
            if (dateDiff !== 0) return dateDiff;

            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        return <CardsAgenda nodes={blogPosts} />
      })}
    </MainLayout>
  )
}

export const agendaQuery = graphql`
  query ($locale: String!, $slug: String!, $tags: [String!]!) {
    allStrapiBlogTag(
      filter: {
        showInAgendPage: { eq: true }
        locale: { eq: $locale }
        id: { in: $tags }
      }
    ) {
      edges {
        node {
          id
        }
      }
    }
    strapiCustomSeo(slug: { eq: $slug }) {
      seo {
        description
        keywords
        title
      }
    }
    allStrapiBlogPost(
      filter: { tag: { id: { in: $tags } } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        id
        slug
        header {
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        date
        pin
        tag {
          id
          name
          backgroundColor
        }
      }
    }
  }
`
