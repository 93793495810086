import React from "react"
import { useTranslation } from "react-i18next"
import CardsWithSwiper from "./CardsWithSwiper"

const params = {
  slidesPerView: 3,
  breakpoints: {
    1200: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 32,
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 32,
    },
  },
}

export default function CardsAgenda({ nodes = [] }) {
  const [node = {}] = nodes
  const { t } = useTranslation()

  return (
    <div className="agenda-card-group">
      <CardsWithSwiper
        nodes={nodes}
        swiperParams={params}
        getCardProps={() => ({
          actions: (
            <button type="button" className="btn agenda-card-detail-btn">
              {t("check")}
            </button>
          ),
        })}
      />

      <div
        className="agenda-card-band"
        style={{ backgroundColor: node.tag?.backgroundColor }}
      />
    </div>
  )
}
